import * as State from 'BaxterScript/version/web/core/State';
import * as Container from 'BaxterScript/version/web/core/Container';
import Consent from 'BaxterScript/version/web/feature/Consent';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import { TargetingParams } from 'BaxterScript/types/TargetingParams';
import { ContainerConfig } from 'BaxterScript/types/Config';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import Placeholder from 'BaxterScript/version/web/feature/Placeholder';
import Cxense from 'BaxterScript/version/web/feature/script/Cxense';
import Gemius from 'BaxterScript/version/web/feature/script/Gemius';
import Sati from 'BaxterScript/version/web/feature/script/Sati';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Html from 'BaxterScript/helper/browser/Html';
import { Features } from 'BaxterScript/version/web/config/Features';
import * as Interstitial from 'BaxterScript/version/web/feature/Interstitial';
import { Slot } from 'BaxterScript/types/Slot';
import { ContainerParams } from 'BaxterScript/types/Baxter';
import { createTargetingParams } from 'BaxterScript/helper/targeting/Targeting';
import { getSegment, propagateSegments } from 'BaxterScript/version/web/core/Segmentation';
import * as Cookie from 'BaxterScript/helper/browser/Cookie';

export const onClear = () => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONCLEAR]');
    Container.remove(State.getContainers(), true);
    State.setContainers({});
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONCLEAR]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_CLEAR_ERROR, { message: (e as Error).message });
  }
};

const removeRestrictedTargeting = (targetingParams: TargetingParams) => {
  const restrictedTargetingKeys = new Set(['user_id', 'user_uuid', 'user_email_sha256', 'segment']);
  const restrictedTargeting = Object.fromEntries(
    Object.entries(targetingParams).filter(([key]) => !restrictedTargetingKeys.has(key))
  );
  console.debug('[SLOTS][LIFECYCLE][REMOVERESTRICTEDTARGETING]', targetingParams, restrictedTargeting);
  return restrictedTargeting;
};

const applyPlaceholderAndTrackPage = (pageId: string, params: TargetingParams) => {
  if (Placeholder) {
    Placeholder.applyToPage(pageId, params);
  }
  if (Cxense) {
    const intervalId = setInterval(() => Cxense.sendPageViewEvent(pageId), 500);
    State.setCxenseIntervalId(intervalId);
  }
  if (Gemius) {
    Gemius.gemiusHit();
  }
  if (Sati) {
    Sati.trackView();
  }
};

const reportParamsToNewRelic = (pageId: string, params: TargetingParams) => {
  if (globalThis.Baxter.config.accountId.startsWith('olx')) {
    for (const [key, value] of Object.entries(params)) {
      if ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value)) {
        newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_ON_PAGE_TARGETING_PARAMS, {
          targetingKey: key,
          pageId,
        });
      }
    }
  }
};

export const onPageChanged = (pageId: string, targetingParams: TargetingParams = {}): void => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONPAGECHANGED]', pageId, targetingParams);
    if (Consent && Consent.delayPageChange(async () => onPageChanged(pageId, targetingParams))) {
      return;
    }
    if (Interstitial?.delayPageChange?.(async () => onPageChanged(pageId, targetingParams))) {
      return;
    }
    onClear();
    const prevPageId = State.getPageId();
    State.setPageId(pageId);
    let params = targetingParams;
    if (params.segment) {
      newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_SEGMENT_DEPRECATED_USAGE);
    }
    if (Consent?.isUserConsentC0004Given() ?? true) {
      State.setSessionLong();
      propagateSegments(params.user_logged_in, params.user_id, State.getSessionLong());
    } else {
      params = removeRestrictedTargeting(params);
    }
    const segment = getSegment();
    const sessionLong = State.getSessionLong();
    const fullParams = {
      ...params,
      ...(segment ? { segment } : null),
      ...(sessionLong ? { sessionLong } : null),
      page: pageId,
      featureFlags: Cookie.get('laquesisff')?.split('#') || [],
      abTest: Cookie.get('laquesis')?.split('#') || [],
    };
    globalThis.Baxter.targeting = removeRestrictedTargeting(fullParams);
    const prevParamsString = JSON.stringify(State.getPageParams());
    const paramsString = JSON.stringify(fullParams);
    if (prevParamsString === paramsString) {
      newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_PAGE_SAME_PARAMS, {
        pageId,
        prevPageId,
        prevParamsString,
        paramsString,
      });
    }
    reportParamsToNewRelic(pageId, params);
    State.setPageParams(fullParams);
    console.debug(
      `[SLOTS][LIFECYCLE][ONPAGECHANGED] PAGE CHANGED: from '${prevPageId}' to '${pageId}'`,
      params,
      fullParams
    );
    State.setBreakpointAndDeviceSize();
    Provider.setPageTargeting(fullParams);
    Provider.setPreview();
    if (Interstitial?.apply?.(pageId, async () => applyPlaceholderAndTrackPage(pageId, fullParams), prevPageId)) {
      return;
    }
    applyPlaceholderAndTrackPage(pageId, fullParams);
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONPAGECHANGED]', e);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_PAGE_CHANGED_ERROR, { message: (e as Error).message });
  }
};

const setContainersSlots = (
  source: string,
  pageId: string,
  containers: ContainerConfig[]
): { loadable: boolean; container: ContainerType }[] =>
  containers
    .map((containerConfig) => {
      const container: ContainerType = {
        config: containerConfig,
        state: {},
      };
      try {
        const params = createTargetingParams(pageId, containerConfig);
        const { refreshCount, existingSlot, slotId } = Container.findSlot(pageId, container, params);
        if (slotId && Interstitial?.isEnabledForSlot?.(pageId, containerConfig.id, slotId)) {
          return {
            container,
            loadable: false,
          };
        }
        return {
          container,
          loadable: Container.createSlot(
            container,
            Container.setSlot(source, pageId, container, params, refreshCount, existingSlot, slotId)
          ),
        };
      } catch (err) {
        newRelicMetrics.reportError(NewRelicError.LIFECYCLE_SET_CONTAINER_SLOT_ERROR, {
          containerId: container.config.id,
          message: (err as Error).message,
        });
        return {
          container,
          loadable: false,
        };
      }
    })
    .filter((containerWithLoadable) => containerWithLoadable.container.state.slot);

const checkDisappearingSlots = (slotsToLoad: Slot[], timeout) => {
  setTimeout(() => {
    slotsToLoad.forEach((slot) => {
      if (!Html.getElementById(slot.innerId)) {
        newRelicMetrics.reportError(NewRelicError.LIFECYCLE_SLOT_DISAPPEARED, {
          pageId: slot.pageId,
          containerId: slot.containerId,
          slotId: slot.id,
          timeout,
        });
      }
    });
  }, timeout);
};

const debounceSet = (delay: number) => {
  let inDebounce: NodeJS.Timeout | string | number | undefined;
  return () => {
    if (inDebounce !== undefined) {
      newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_SET_DEBOUNCED, { delay });
      clearTimeout(inDebounce);
    }
    inDebounce = setTimeout(() => {
      clearTimeout(inDebounce);
      inDebounce = undefined;
    }, delay);
  };
};

const debounceSet20 = debounceSet(20);
const debounceSet50 = debounceSet(50);

export const onSet = async (paramsByContainerId: ContainerParams = {}) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSET]', paramsByContainerId);
    const shouldSetSpecificContainers: boolean = !!Object.keys(paramsByContainerId).length;
    if (
      Consent &&
      (shouldSetSpecificContainers
        ? Consent.delaySetSpecificContainers(async () => {
            await onSet(paramsByContainerId);
          })
        : Consent.delaySet(async () => {
            await onSet(paramsByContainerId);
          }))
    ) {
      return;
    }
    if (
      shouldSetSpecificContainers
        ? Interstitial?.delaySetSpecificContainers?.(async () => onSet(paramsByContainerId))
        : Interstitial?.delaySet?.(async () => onSet(paramsByContainerId))
    ) {
      return;
    }
    newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_ON_SET);
    debounceSet20();
    debounceSet50();
    const pageId = State.getPageId() as string;
    let pageContainers: ContainerConfig[] = globalThis.Baxter.config.containers?.[pageId] || [];
    if (pageContainers.length) {
      if (shouldSetSpecificContainers) {
        pageContainers = pageContainers.filter((pageContainer) => paramsByContainerId[pageContainer.id]);
        const stateContainers = pageContainers.reduce((result, pageContainer) => {
          const container = State.getContainer(pageContainer.id);
          if (container) {
            // eslint-disable-next-line no-param-reassign
            result[pageContainer.id] = container;
          }
          return result;
        }, {});
        Container.remove(stateContainers, false);
      }
      console.debug('[SLOTS][LIFECYCLE][ONSET] setContainerSlots', paramsByContainerId, pageContainers);
      const containersWithLoadable = setContainersSlots(Features.NON_LAZY_LOAD, pageId, pageContainers);
      const containersByIds = containersWithLoadable.reduce((result, containerWithLoadable) => {
        // eslint-disable-next-line no-param-reassign
        result[containerWithLoadable.container.config.id] = containerWithLoadable.container;
        return result;
      }, {});
      if (shouldSetSpecificContainers) {
        State.setContainers({
          ...State.getContainers(),
          ...containersByIds,
        });
      } else {
        State.setContainers(containersByIds);
      }
      console.debug('[SLOTS][LIFECYCLE][ONSET] onload');
      const slotsToLoad = containersWithLoadable
        .filter((containerWithLoadable) => containerWithLoadable.loadable)
        .map((containerWithLoadable) => containerWithLoadable.container.state.slot!);
      await Provider.load(Features.NON_LAZY_LOAD, slotsToLoad);
      checkDisappearingSlots(slotsToLoad, 20);
      checkDisappearingSlots(slotsToLoad, 50);
    } else {
      console.error('[SLOTS][LIFECYCLE][ONSET] NO CONTAINERS FOUND FOR PAGE', pageId);
      newRelicMetrics.reportError(NewRelicError.LIFECYCLE_NO_CONTAINERS_FOUND_FOR_PAGE, { pageId });
    }
  } catch (error) {
    console.error('[SLOTS][LIFECYCLE][ONSET]', error);
    newRelicMetrics.reportError(NewRelicError.LIFECYCLE_ON_SET_ERROR, { message: (error as Error).message });
  }
};

/**
 * @deprecated
 * Use onSet instead;
 */
export const onSetAfterLoaded = async (paramsByContainerId: ContainerParams = {}) => {
  newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_ON_SET_AFTER_LOADED_DEPRECATED_USAGE);
  await onSet(paramsByContainerId);
};

/**
 * @deprecated
 * OnPageChange should be enough.
 */
export const onSetPageParams = () => {
  newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_ON_PAGE_PARAMS_DEPRECATED_USAGE);
};

/**
 * @deprecated
 * Deprecated due to duplicated events from CUs and because baxter is aware of every page change
 */
export const onPageTrack = async () => {
  newRelicMetrics.reportMetric(NewRelicMetric.LIFECYCLE_ON_PAGE_TRACK_DEPRECATED_USAGE);
};

export const onSetTopSticky = (css: Partial<CSSStyleDeclaration>) => {
  try {
    console.info('[SLOTS][LIFECYCLE][ONSETTOPSTICKY]', css);
    window.dispatchEvent(new CustomEvent('baxter:topSticky', { detail: css }));
  } catch (e) {
    console.error('[SLOTS][LIFECYCLE][ONSETTOPSTICKY]', e);
    newRelicMetrics.reportError(NewRelicError.SET_TOP_STICKY_ERROR, { message: (e as Error).message });
  }
};
