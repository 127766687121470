import { Config } from 'BaxterScript/types/Config';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Callbacks, LazyLoadSlot, Slot } from 'BaxterScript/types/Slot';
import { Features } from 'BaxterScript/version/web/config/Features';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { ContainerType, FindAndSetAndCreateSlotFunction } from 'BaxterScript/types/ContainerType';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import * as Observer from 'BaxterScript/helper/browser/Observer';

export const id = Features.LAZY_LOAD;

export const webpackExclude = (config: Config): boolean => {
  const settings = config.slots?.settings?.lazyLoad;
  return !(
    (settings?._ && Object.values(settings._).some((item) => !!item?.enabled)) ||
    (settings && Object.values(settings).some((item) => !!item?.enabled))
  );
};

const loadSlot = async (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
) => {
  const loadable = findAndSetAndCreateSlot(id, lazyLoadSlot.pageId, container, lazyLoadSlot.params, true);
  if (loadable) {
    if (container.state.slot === lazyLoadSlot) {
      Provider.create(container.state.slot as Slot, callbacks);
    }
    await Provider.load(id, [container.state.slot as Slot]);
  }
};

const removeObserver = (containerHtmlElement: HTMLElement, slot: LazyLoadSlot) => {
  console.info('[SLOTS][LAZYLOAD][REMOVEOBSERVER]');
  Observer.removeObserver(containerHtmlElement, slot[id].state);
};

const addIntersectionObserver = (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
): void => {
  console.info('[SLOTS][LAZYLOAD][ADDINTERSECTIONOBSERVER]');
  const distanceFromViewport = lazyLoadSlot[id].config.distanceFromViewport || 0;
  const rootMargin = `${distanceFromViewport}px 0px ${distanceFromViewport}px 0px`;

  // eslint-disable-next-line no-param-reassign
  lazyLoadSlot[id].state.intersectionObserver = Observer.createIntersectionObserver(
    container.state.htmlElement as HTMLElement,
    {
      rootMargin,
      onIntersect: async () => {
        if (!lazyLoadSlot[id].state.loaded) {
          // eslint-disable-next-line no-param-reassign
          lazyLoadSlot[id].state.loaded = true;
          removeObserver(container.state.htmlElement as HTMLElement, lazyLoadSlot);
          await loadSlot(container, lazyLoadSlot, callbacks, findAndSetAndCreateSlot);
        }
      },
    },
    'LAZYLOAD',
    NewRelicError.LAZY_LOAD_INTERSECTION_OBSERVER_ERROR
  );
};

const applyToSlot = (
  container: ContainerType,
  lazyLoadSlot: LazyLoadSlot,
  callbacks: Callbacks,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
): void => {
  console.info('[SLOTS][LAZYLOAD][APPLYTOSLOT]', lazyLoadSlot);
  addIntersectionObserver(container, lazyLoadSlot, callbacks, findAndSetAndCreateSlot);
};

export const apply = (
  container: ContainerType,
  callbacks: Callbacks,
  findAndSetAndCreateSlot: FindAndSetAndCreateSlotFunction
): boolean => {
  const slot = container.state.slot as Slot;
  // eslint-disable-next-line no-param-reassign
  slot[id] = {
    config:
      getConfigById(globalThis.Baxter.config?.slots?.settings?.lazyLoad, slot.pageId, slot.containerId, slot.id) || {},
    state: {},
  };
  if (!slot[id].config.enabled) {
    return false;
  }
  if (slot[id].state.alreadyApplied) {
    return false;
  }
  // eslint-disable-next-line no-param-reassign
  slot[id].state.alreadyApplied = true;
  applyToSlot(container, slot as LazyLoadSlot, callbacks, findAndSetAndCreateSlot);
  return true;
};

export const remove = (containerHtmlElement: HTMLElement, slot: Slot): void => {
  if (slot[id]?.config?.enabled) {
    console.info('[SLOTS][LAZYLOAD][REMOVE]', slot);
    removeObserver(containerHtmlElement, slot as LazyLoadSlot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.loaded = false;
    // eslint-disable-next-line no-param-reassign
    slot[id].state.alreadyApplied = false;
  }
};

// eslint-disable-next-line import/no-default-export
export default {
  apply,
  remove,
};
