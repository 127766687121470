import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { GoogleAdsConfig, GoogleAdsInterstitialConfig } from 'BaxterScript/types/ProviderSlotConfig/GoogleAds';
import { Config, ContainerConfig } from 'BaxterScript/types/Config';
import { InterstitialSlot, Slot } from 'BaxterScript/types/Slot';
import * as State from 'BaxterScript/version/web/core/State';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import { convertMinutesToMilliseconds } from 'BaxterScript/helper/time/TimeConvert';
import { Features } from 'BaxterScript/version/web/config/Features';
import ninjaMetrics, { NinjaMetrics } from 'BaxterScript/helper/metrics/NinjaMetrics';
import { NinjaMetric } from 'BaxterScript/helper/metrics/NinjaMetric';
import * as Html from 'BaxterScript/helper/browser/Html';
import * as Provider from 'BaxterScript/version/web/core/Provider';
import { Debounce } from 'BaxterScript/helper/event/Debounce';
import { getConfigById } from 'BaxterScript/helper/config/Config';
import { LifecycleQueue } from 'BaxterScript/helper/queue/LifecycleQueue';
import { Cmd } from 'BaxterScript/helper/queue/Queue';
import { ContainerType } from 'BaxterScript/types/ContainerType';
import * as Container from 'BaxterScript/version/web/core/Container';
import { createTargetingParams } from 'BaxterScript/helper/targeting/Targeting';
import { FeatureFlags } from 'BaxterScript/version/web/config/Flags';
import { NinjaEventType } from 'BaxterScript/helper/metrics/NinjaEventType';

export const id = Features.INTERSTITIAL;
const BAXTER_TOP_LEVEL_DIV_ID = 'baxter-top-level';
const MODAL_CLASS_NAME = 'baxter-interstitial-modal';

const lifecycleQueue = new LifecycleQueue('INTERSTITIAL');

export const webpackExclude = (config: Config) => {
  const providerSettings = (config.slots?.providerSettings?.[Providers.GOOGLE_ADS] ?? {}) as GoogleAdsConfig;
  const interstitialSettings = providerSettings.interstitial;
  return !(
    (interstitialSettings?._ && Object.values(interstitialSettings._).some((item) => item?.enabled === true)) ||
    (interstitialSettings && Object.values(interstitialSettings).some((item) => item?.enabled === true))
  );
};

const appendTopLevelDiv = (divId: string) => {
  let topLevelDiv = Html.getElementById(divId);
  if (!topLevelDiv) {
    console.debug('[SLOTS][INTERSTITIAL][APPENDTOPLEVELDIV]', divId);
    topLevelDiv = document.createElement('div');
    topLevelDiv.setAttribute('id', divId);
    topLevelDiv.setAttribute('style', 'display: fixed;');
    document.body.appendChild(topLevelDiv);
  }
  return topLevelDiv;
};

const removeModal = (slot: InterstitialSlot) => {
  console.info('[SLOTS][INTERSTITIAL][REMOVEMODAL]', slot);
  if (slot[id].state.interval) {
    clearInterval(slot[id].state.interval);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.interval = undefined;
  }
  if (slot[id].state.timeout) {
    clearTimeout(slot[id].state.timeout);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.timeout = undefined;
  }
  document.body.classList.remove('baxter-interstitial-overflow-hidden');
  if (slot[id].state.modal) {
    slot[id].state.modal.remove();
    // eslint-disable-next-line no-param-reassign
    slot[id].state.modal = undefined;
  }
  State.removeInterstitialSlot(slot[id].state.prevPageId as string, slot[id].state.pageId as string);
  Provider.remove([slot], false);
};

const closeModal = (slot: InterstitialSlot) => {
  console.info('[SLOTS][INTERSTITIAL][CLOSEMODAL]', slot);
  newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_CLOSE_MODAL);
  removeModal(slot);
  setTimeout(async () => {
    try {
      await lifecycleQueue.process();
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSEMODAL][TIMEOUT]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_CLOSE_MODAL_TIMEOUT_ERROR, {
        message: (e as Error).message,
      });
    }
  }, 1);
};

const validSize = (modal: HTMLElement) => {
  const height =
    modal.getElementsByClassName('baxter-interstitial-modal-content')[0].getBoundingClientRect().height +
    modal.getElementsByClassName('baxter-interstitial-modal-footer')[0].getBoundingClientRect().height;
  console.log(height);
  const windowHeight = window.innerHeight;
  const isValidSize = height <= windowHeight;
  if (!isValidSize) {
    console.debug('[SLOTS][INTERSTITIAL][VALIDSIZE] invalid size', height, windowHeight);
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_INVALID_SIZE, { height, windowHeight });
  } else {
    console.debug('[SLOTS][INTERSTITIAL][VALIDSIZE] valid size', height, windowHeight);
  }
  return isValidSize;
};

const closeModalWithInvalidSize = () => {
  Object.values(State.getInterstitialSlots()).forEach((slot) => {
    if (slot[id].state.modal && !validSize(slot[id].state.modal)) {
      closeModal(slot);
    }
  });
};

const clearModal = () => {
  console.info('[SLOTS][INTERSTITIAL][CLEARMODAL]', State.getInterstitialSlots());
  let cleared = false;
  Object.values(State.getInterstitialSlots()).forEach((slot) => {
    if (slot[id].state.modal) {
      cleared = true;
      removeModal(slot);
    }
  });
  lifecycleQueue.removeAll();
  if (cleared) {
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_CLEAR_MODAL);
  }
};

export const bootstrap = () => {
  window.addEventListener(
    'resize',
    Debounce(async () => {
      try {
        console.info('[SLOTS][INTERSTITIAL][RESIZE]');
        closeModalWithInvalidSize();
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][RESIZE]', e);
        newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_RESIZE_LISTENER_ERROR, {
          message: (e as Error).message,
        });
      }
    }, 300)
  );
  window.addEventListener('popstate', async () => {
    try {
      console.info('[SLOTS][INTERSTITIAL][POPSTATE]');
      clearModal();
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][POPSTATE]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_POPSTATE_LISTENER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
};

const validFrequencyCap = (config, prevPageId: string, pageId: string) => {
  const frequencyCapInMs = convertMinutesToMilliseconds(config.frequencyCap || 1440);
  const lastRendering = Number(State.getInterstitialLastUsage(prevPageId, pageId)) || 0;
  const now = Date.now();
  const frequencyCap = lastRendering + frequencyCapInMs;
  const isValidFrequencyCap = Date.now() > frequencyCap;
  if (!isValidFrequencyCap) {
    console.debug(
      '[SLOTS][INTERSTITIAL][VALIDFREQUENCYCAP] invalid frequency cap',
      new Date(now),
      new Date(frequencyCap)
    );
    newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_INVALID_FREQUENCY_CAP);
  } else {
    console.debug(
      '[SLOTS][INTERSTITIAL][VALIDFREQUENCYCAP] valid frequency cap',
      new Date(now),
      new Date(frequencyCap)
    );
  }
  return isValidFrequencyCap;
};

const slotModalContainerId = (innerId: string) => `${innerId}-modal-container`;

const createModalDiv = (slot: InterstitialSlot) => {
  const modal = document.createElement('div');
  modal.setAttribute('id', slotModalContainerId(slot.innerId));
  modal.setAttribute('style', 'display:none');
  modal.setAttribute('class', 'baxter-interstitial');
  modal.innerHTML = `<div class="${MODAL_CLASS_NAME}">
        <div id="baxter-interstitial-modal-content" class="baxter-interstitial-modal-content"></div>
        <div class="baxter-interstitial-modal-footer">
            <div class="baxter-interstitial-modal-footer-content">
                <div class="baxter-interstitial-modal-footer-content-title">
                    ${slot[id].config.modalTitle}
                </div>
                <small class="baxter-interstitial-modal-footer-content-subtitle${slot[id].config.autoClose ? '' : '--hidden'}">
                    ${slot[id].config.autoCloseText}&nbsp;
                    <span id="baxter-interstitial-modal-footer-countdown-${slot.innerId}"></span>
                </small>
            </div>
            <button class="baxter-interstitial-modal-footer-close-btn">
                <span class="baxter-interstitial-modal-footer-close-btn-title">
                    ${slot[id].config.closeButtonTitle}&nbsp;
                    <span class="baxter-interstitial-modal-footer-close-btn-title-icon">&times;</span>
                </span>
            </button>
        </div>
    </div>`;

  modal.getElementsByClassName('baxter-interstitial-modal-footer-close-btn')?.[0].addEventListener('click', () => {
    try {
      closeModal(slot);
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][CLOSECLICKHANDLER]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_CLOSE_CLICK_HANDLER_ERROR, {
        message: (e as Error).message,
      });
    }
  });
  return modal;
};

const insertModal = (topLevelDiv: HTMLElement, slot: InterstitialSlot): HTMLElement => {
  const modal = createModalDiv(slot);
  topLevelDiv.appendChild(modal);
  document.getElementById('baxter-interstitial-modal-content')!.appendChild(slot.innerHtmlElement);
  return modal;
};

const showCloseTimer = (slot: InterstitialSlot) => {
  let autoClose = (slot[id].config.autoClose || 1) - 1;
  // eslint-disable-next-line no-param-reassign
  slot[id].state.interval = setInterval(() => {
    try {
      if (autoClose > 0) {
        const element = document.getElementById(`baxter-interstitial-modal-footer-countdown-${slot.innerId}`);
        if (element) {
          element.innerHTML = `${autoClose}s`;
        }
        autoClose -= 1;
      }
    } catch (e) {
      console.error('[SLOTS][INTERSTITIAL][SHOWCLOSETIMER]', e);
      newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SHOW_CLOSE_TIMER_ERROR, { message: (e as Error).message });
    }
  }, 1000);
};

const setAutoClose = (slot: InterstitialSlot) => {
  if (slot[id].config.autoClose) {
    const autoCloseInMs = slot[id].config.autoClose * 1000;
    console.debug('[SLOTS][INTERSTITIAL][SETAUTOCLOSE]', autoCloseInMs);
    showCloseTimer(slot);
    // eslint-disable-next-line no-param-reassign
    slot[id].state.timeout = setTimeout(() => {
      try {
        closeModal(slot);
      } catch (e) {
        console.error('[SLOTS][INTERSTITIAL][SETAUTOCLOSE][TIMEOUT]', e);
        newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SET_AUTO_CLOSE_TIMEOUT_ERROR, {
          message: (e as Error).message,
        });
      }
    }, autoCloseInMs);
  }
};

const interstitialConfig = (pageId, containerId, slotId) => {
  const providerSettings = (globalThis.Baxter.config.slots?.providerSettings?.[Providers.GOOGLE_ADS] ||
    {}) as GoogleAdsConfig;
  return (getConfigById(providerSettings.interstitial, pageId, containerId, slotId) ||
    {}) as GoogleAdsInterstitialConfig;
};

const interstitialAlreadyCreated = () =>
  !!Object.values(State.getInterstitialSlots()).filter((slot) => slot[id].state.modal).length;

export const isInterstitialEnabled = (config) => config?.enabled;

export const isEnabledForSlot = (pageId, containerId, slotId) =>
  isInterstitialEnabled(interstitialConfig(pageId, containerId, slotId));

const findInterstitial = (prevPageId: string, pageId: string) => {
  const pageContainers: ContainerConfig[] = globalThis.Baxter.config.containers?.[pageId] || [];
  return pageContainers
    .map((containerConfig) => {
      const container: ContainerType = {
        config: containerConfig,
        state: {},
      };
      const params = createTargetingParams(pageId, containerConfig);
      const { refreshCount, existingSlot, slotId } = Container.findSlot(pageId, container, params);
      if (!slotId) {
        return;
      }
      const config = interstitialConfig(pageId, containerConfig.id, slotId);
      if (!isInterstitialEnabled(config)) {
        return;
      }
      if (prevPageId !== config.sourcePage) {
        console.debug(
          `[SLOTS][INTERSTITIAL][FINDINTERSTITIAL] ${slotId} not requesting because invalid source page ${prevPageId} Should be: ${config.sourcePage}`
        );
        return;
      }
      if (!validFrequencyCap(config, prevPageId, pageId)) {
        console.debug(`[SLOTS][INTERSTITIAL][FINDINTERSTITIAL] ${slotId} not requesting because invalid frequency cap`);
        return;
      }
      return {
        container,
        refreshCount,
        existingSlot,
        slotId,
        params,
        config,
      };
    })
    .filter((interstitial) => interstitial)?.[0];
};

export const delayPageChange = (cmd: Cmd): boolean => {
  if (interstitialAlreadyCreated()) {
    console.debug('[SLOTS][INTERSTITIAL][DELAYPAGECHANGE] delaying because interstitial already created');
    lifecycleQueue.delayPageChange(cmd, true);
    return true;
  }
  return false;
};

export const apply = (pageId: string, cmd: Cmd, prevPageId?: string | null): boolean => {
  let interstitialSlot;
  try {
    if (!prevPageId) {
      console.debug('[SLOTS][INTERSTITIAL][APPLY] not requesting because missing prev page id');
      return false;
    }

    const findResult = findInterstitial(prevPageId, pageId);
    if (!findResult) {
      return false;
    }
    if (!FeatureFlags.EUADS_6300_NO_ADS(findResult.params)) {
      appendTopLevelDiv(findResult.container.config.id);
    }
    const setResult = Container.setSlot(
      id,
      pageId,
      findResult.container,
      findResult.params,
      findResult.refreshCount,
      findResult.existingSlot,
      findResult.slotId
    );
    if (!setResult.success) {
      console.debug('[SLOTS][INTERSTITIAL][APPLY] not requesting because set slot failed');
      return false;
    }
    // eslint-disable-next-line no-param-reassign
    interstitialSlot = setResult.slot! as InterstitialSlot;
    interstitialSlot[id] = {
      config: findResult.config,
      state: {},
    };
    interstitialSlot[id].state.prevPageId = prevPageId;
    interstitialSlot[id].state.pageId = pageId;
    console.debug('[SLOTS][INTERSTITIAL][APPLY] store in state', interstitialSlot);
    State.setInterstitialSlot(prevPageId, pageId, interstitialSlot as InterstitialSlot);
    const topLevelDiv = appendTopLevelDiv(BAXTER_TOP_LEVEL_DIV_ID);
    interstitialSlot[id].state.modal = insertModal(topLevelDiv, interstitialSlot);
    document.body.classList.add('baxter-interstitial-overflow-hidden');
    const callbacks = Container.createCallbacks(findResult.container, Container.findAndSetAndCreateSlot);
    const created = Provider.create(interstitialSlot, {
      impressionViewableCallback: (source: string, slot: Slot, ninjaParameters: Record<string, unknown>) => {
        ninjaMetrics.reportMetric(
          NinjaMetric.INTERSTITIAL_VIEWABLE,
          NinjaMetrics.extendedParameters(source, slot, NinjaEventType.CLICK, ninjaParameters)
        );
        return callbacks.impressionViewableCallback(source, slot, ninjaParameters);
      },
      slotRenderEndedCallback: (
        source: string,
        slot: Slot,
        isEmpty: boolean,
        hasVideo?: boolean,
        ninjaParameters?: Record<string, unknown>
      ) => {
        if (isEmpty) {
          console.debug(
            '[SLOTS][INTERSTITIAL][SLOTRENDERENDEDCALLBACK] no ad to show',
            interstitialSlot[id].state.slotKey,
            interstitialSlot
          );
          closeModal(interstitialSlot);
        } else {
          console.debug(
            '[SLOTS][INTERSTITIAL][SLOTRENDERENDEDCALLBACK] showing modal',
            interstitialSlot[id].state.slotKey,
            interstitialSlot
          );
          newRelicMetrics.reportMetric(NewRelicMetric.INTERSTITIAL_SHOW_MODAL);
          ninjaMetrics.reportMetric(
            NinjaMetric.INTERSTITIAL_IMPRESSION,
            NinjaMetrics.extendedParameters(source, slot, NinjaEventType.PAGE, ninjaParameters)
          );
          interstitialSlot[id].state.modal?.style?.removeProperty?.('display');
          setAutoClose(interstitialSlot);
        }
        return callbacks.slotRenderEndedCallback(source, slot, isEmpty, hasVideo, ninjaParameters);
      },
      slotClickedCallback: (source: string, slot: Slot, ninjaParameters: Record<string, unknown>) => {
        ninjaMetrics.reportMetric(
          NinjaMetric.INTERSTITIAL_CLICKED,
          NinjaMetrics.extendedParameters(source, slot, NinjaEventType.CLICK, ninjaParameters)
        );
        return callbacks.slotClickedCallback(source, slot, ninjaParameters);
      },
    });
    if (!created) {
      console.debug('[SLOTS][INTERSTITIAL][APPLY] slot creation failed');
      closeModal(interstitialSlot);
      return false;
    }
    State.setInterstitialLastUsage(prevPageId, pageId);
    lifecycleQueue.delayPageChange(cmd, true);
    console.debug('[SLOTS][INTERSTITIAL][APPLY] requesting ads');
    // not awaiting on purpose to not make this method async
    Provider.load(id, [interstitialSlot]);
    return true;
  } catch (e) {
    console.error('[SLOTS][INTERSTITIAL][APPLY]', e);
    newRelicMetrics.reportError(NewRelicError.INTERSTITIAL_SHOW_MODAL_ERROR, { message: (e as Error).message });
    if (interstitialSlot) {
      closeModal(interstitialSlot);
    }
    return false;
  }
};

export const delaySet = (cmd: Cmd) => lifecycleQueue.delaySet(cmd, interstitialAlreadyCreated());

export const delaySetSpecificContainers = (cmd: Cmd) =>
  lifecycleQueue.delaySetSpecificContainers(cmd, interstitialAlreadyCreated());
